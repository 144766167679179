import React from 'react';
import Header from './header/header';
import Footer from './footer/footer';
// import '../../styles/main.scss';
import { Theme } from './theme/theme';

const Layout = ({ children }) => {
  return (
    <Theme>
      <Header />
      <main>{children}</main>
      {/* <Footer /> */}
    </Theme>
  );
};

export default Layout;
