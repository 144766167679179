import { useEffect } from 'react';

const useHeaderHook = () => {
  useEffect(() => {
    function debounce(func, wait = 10, immediate = true) {
      let timeout;
      return function() {
        let context = this,
          args = arguments;
        let later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }
    let scrollPos = 0;
    const mobileNav = document.querySelector('header');
    const navLinks = document.querySelectorAll('header a');
    const svgText = document.querySelectorAll('header a svg path');

    function checkPosition() {
      let windowY = window.scrollY;
      if (windowY < scrollPos && windowY > 100) {
        // Scrolling UP
        mobileNav.classList.add('fixed-top');
        mobileNav.classList.add('nav-bkg');
        navLinks.forEach(link => {
          link.setAttribute('style', 'color: #1b1e3c;');
        });
        mobileNav.classList.remove('nav-up');
      } else if (windowY < 125) {
        mobileNav.classList.add('fixed-top');
        navLinks.forEach(link => {
          link.setAttribute('style', 'color: #ffffff;');
        });
        svgText.forEach(text => {
          text.classList.add('fill-dark-purple');
          text.classList.add('fill-white');
        });
        mobileNav.classList.remove('nav-bkg');
        mobileNav.classList.remove('nav-up');
      } else {
        // Scrolling DOWN
        mobileNav.classList.add('nav-up');
        navLinks.forEach(link => {
          link.removeAttribute('style', 'color: #1b1e3c');
        });
        mobileNav.classList.remove('nav-bkg');
        mobileNav.classList.remove('fixed-top');
      }
      scrollPos = windowY;
    }

    window.addEventListener('scroll', debounce(checkPosition));
  });
};

export default useHeaderHook;
