import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './style';

export const ThemeContext = React.createContext();

export const Theme = ({ children }) => {
  const theme = {
    color: {
      peach: '#DD645E',
      lightPurple: '#98A3F4',
      opaquePurple: 'rgba(152, 163, 244, 0.2)',
      darkPurple: '#1b1e3c',
      white: '#ffffff',
      gray: '#B3A5B8',
    },
    radius: {
      small: '3px',
      big: '50px',
    },
    font: {
      family: {
        primary: 'Roboto Mono',
      },
      weight: {
        regular: 400,
        bold: 700,
      },
    },
    easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
    breakpoints: {
      small: '600px',
      medium: '1200px',
      large: '1600px',
      huge: '2200px',
    },
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
      }}
    >
      <ThemeContext.Consumer>
        {({ theme }) => (
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyles />
              {children}
            </>
          </ThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  );
};

// export const globalThemeFragment = graphql`
//   fragment globalTheme on SettingsJson {
//     color {
//       peach
//       lightPurple
//       opaquePurple
//       darkPurple
//       white
//       gray
//     }
//     font {
//       family {
//         primary
//       }
//       weight {
//         regular
//         bold
//       }
//     }
//     easing
//     breakpoints {
//       small
//       medium
//       large
//       huge
//     }
//   }
// `;

export const ThemeForm = {
  label: 'Theme',
  fields: [
    {
      label: 'Color',
      name: 'rawJson.color',
      component: 'group',
      fields: [
        {
          label: 'Peach',
          name: 'peach',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'Light Purple',
          name: 'lightPurple',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'Opaque Purple',
          name: 'opaquePurple',
          component: 'color',
          colorFormat: 'rgb',
        },
        {
          label: 'Dark Purple',
          name: 'darkPurple',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'White',
          name: 'white',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'Gray',
          name: 'gray',
          component: 'color',
          colorFormat: 'hex',
        },
      ],
    },
  ],
};
