import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import LogoImage from './logo.svg';

const Brand = () => (
  <LogoContainer>
    <Link to='/' className=''>
      <Logo />
    </Link>
  </LogoContainer>
);

export const LogoContainer = styled.div`
  text-align: left;
  width: 25%;
  position: relative;
  z-index: 5;
`;

export const Logo = styled(LogoImage)`
  width: 50px;
  height: auto;
  transform: translateY(1px);
`;

export default Brand;
