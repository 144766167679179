import React from 'react';
import styled from 'styled-components';

import GitHub from '../../../../content/assets/github.svg';
import LinkedIn from '../../../../content/assets/linkedin.svg';
import Twitter from '../../../../content/assets/twitter.svg';

const Social = () => {
  return (
    <SocialContainer>
      <SocialLink
        href='https://github.com/jontallboy'
        target='_blank'
        rel='noopener noreferrer'
      >
        <GitHub
          alt='jon miller github'
          style={{ width: '25px', height: 'auto' }}
        />
      </SocialLink>
      <SocialLink
        href='https://www.linkedin.com/in/jon-h-miller/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <LinkedIn
          alt='jon miller linkedin'
          style={{ width: '25px', height: 'auto' }}
        />
      </SocialLink>
      <SocialLink
        href='https://twitter.com/jontallboy'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Twitter
          alt='jon miller twitter'
          style={{ width: '25px', height: 'auto' }}
        />
      </SocialLink>
    </SocialContainer>
  );
};

export const SocialContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 25%;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    display: none;
  }
`;

export const SocialLink = styled.a`
  color: ${props => props.theme.color.peach};
  text-decoration: none;
  margin: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export default Social;
