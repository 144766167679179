import React from 'react';
import styled from 'styled-components';

import Brand from './brand';
import Menu from './menu';
import Social from './social';
import useHeaderHook from '../../../hooks/useHeaderHook';

const Header = () => {
  useHeaderHook();
  return (
    <NavContainer>
      <Brand />
      {/* <Menu /> */}
      <Social />
    </NavContainer>
  );
};

export const NavContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 7%;
  z-index: 3;
`;

export default Header;
