import React from 'react';
import styled from 'styled-components';

const Footer = () => (
  <FooterContainer>
    © copyright&nbsp;{new Date().getFullYear()}&nbsp;
    <a href='https://www.github.com/jonmiller0/'>Jon Miller</a>
  </FooterContainer>
);

export const FooterContainer = styled.footer`
  background-color: ${props => props.theme.color.darkPurple};
  color: ${props => props.theme.color.white};
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  h3 {
    color: ${props => props.theme.color.white};
    margin-bottom: 0;
  }
`;

export default Footer;
