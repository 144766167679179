import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';

import useWindowWidth from '../../../hooks/useWindowWidth';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const width = useWindowWidth();

  const data = {
    menuItems: [
      {
        title: 'Home',
        link: '/',
        emoji: '🏠 ',
      },
      {
        title: 'Contact',
        link: '/contact',
        emoji: '🖐 ',
      },
    ],
  };

  const nav = data;

  if (typeof width !== undefined && width < 600) {
    return (
      <MobileHeaderContainer onClick={toggle}>
        <Line
          translateX={isOpen ? '0px' : '0px'}
          translateY={isOpen ? '0px' : '-5px'}
          rotateDegree={isOpen ? '45deg' : '0deg'}
        />
        <Line
          translateX={isOpen ? '0px' : '0px'}
          translateY={isOpen ? '-5px' : '0px'}
          rotateDegree={isOpen ? '-45deg' : '0deg'}
        />
        <MenuContainer menuOpen={isOpen}>
          <MenuList>
            {nav.menuItems.map((item, i) => (
              <MenuItem key={i}>
                <span role='img' aria-label='emoji'>
                  {item.emoji}
                </span>
                <Link to={item.link}>{item.title}</Link>
              </MenuItem>
            ))}
          </MenuList>
        </MenuContainer>
      </MobileHeaderContainer>
    );
  }
  return (
    <Nav>
      {nav.menuItems.map((item, i) => (
        <NavListItem key={i}>
          <NavLink to={item.link}>{item.title}</NavLink>
        </NavListItem>
      ))}
    </Nav>
  );
};

export const Nav = styled.ul`
  width: 75%;
  height: auto;
  margin: auto;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavListItem = styled.li`
  text-decoration: none;
  margin: 0;
  padding: 12px;
  text-align: center;
`;

export const NavLink = styled(Link)`
  border-bottom: 3px solid transparent;
  padding: 0 10px 5px 10px;
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.font.weight.bold};

  &:hover {
    border-bottom: 3px solid ${props => props.theme.color.peach};
  }
`;

export const MobileHeaderContainer = styled.div`
  position: relative;
  color: #fff;
`;

export const MenuContainer = styled.div`
  display: none;
  ${props =>
    props.menuOpen &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: ${props => props.bkgColor || '#1b1e3c'};
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export const MenuList = styled.ul`
  list-style: none;
  transform: translateY(-50%);
`;

export const MenuItem = styled.li`
  color: white;

  a {
    text-decoration: none;
    font-size: 2rem;
    line-height: 1.5;
    color: #fff;
  }

  span {
    font-size: 2rem;
  }
`;

export const Line = styled.span`
  display: block;
  width: 30px;
  height: 5px;
  background-color: #fff;

  &:nth-child(1) {
    transform: translate(
        ${props => props.translateX},
        ${props => props.translateY}
      )
      rotate(${props => props.rotateDegree});
    transition: all 0.3s ease;
  }

  &:nth-child(2) {
    transform: translate(
        ${props => props.translateX},
        ${props => props.translateY}
      )
      rotate(${props => props.rotateDegree});
    transition: all 0.3s ease;
  }

  z-index: 100;
`;

export default Menu;
